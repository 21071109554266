@import "./../layout.scss";
.container {
  padding-top: 126px;
  padding-bottom: 50px;
  min-height: calc(100vh - #{$main-header-height + $tab-header-height});
  display: flex;
  flex-direction: column;
  align-items: center;

  >div {
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 2px;
  }

  a {
    margin-top: 45px;
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 104px;
  padding: 40px;
}

.not-reachable {
  margin-top: 18px;
}

.coming-soon {
  margin-bottom: 30px;
}

.forbidden {
  margin-bottom: 49px;
}