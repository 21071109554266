@use "@netapp/bxp-design-system-react/styles/cm-layout" as cmLayout;
@use "@netapp/bxp-design-system-react/styles/mixins";
@import "@netapp/bxp-design-system-react/styles/colors";

.base {
    height: calc(100vh - #{cmLayout.$main-header-height}) !important;
}

.cardBase {
    height: 'auto';
    padding: 48px 48px;
    width: 658px;
}

.infoSection {
    background-color: var(--notification-information-bg);
    padding: 24px;
}