@keyframes cc-rotate-dash-animation {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100,200;
    stroke-dashoffset: -15;
  }
  100% {
    stroke-dasharray: 100,200;
    stroke-dashoffset: -125;
  }
}

@keyframes cc-rotate-animation {
  100% {
    transform: rotate(360deg);
  }
}

.base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  [class*="primary-stroke"] {
    stroke: var(--icon-primary);
  }

  svg {
    animation: cc-rotate-animation 1.4s linear infinite;
    transform-origin: center center;
    width: 88px;
    height: 88px;
  }

  circle{
    stroke-dasharray: 80,200;
    stroke-dashoffset: 0;
    animation: cc-rotate-dash-animation 1.4s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}
