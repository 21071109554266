@import "@netapp/bxp-design-system-react/styles/zindex";


.common-screen {
  z-index: $full-screen-z;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  position: fixed;
  background-color: var(--background-content);
  overflow: auto;
  transition: opacity 1.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  .common-header {
    height: 200px;
    background: url("header.svg") no-repeat 0;
    background-size: cover;
    display: flex;
  }

  .move-to-saas {
    width: 1004px;
    margin: 57px auto;
    display: flex;
    margin-bottom: 25px;
    color: var(--text-primary);
  }
  .sub-header {
    width: 482px;
    margin: auto;
    text-align: center;
    font-weight: 600;
    color: var(--background-content);
  }
  .move-saas-title {
    font-size: 30px;
    line-height: 1.2;
    color: var(--shell-header-netapp-primary-text);
  }
  .move-saas-sub-title {
    text-decoration: underline;
    font-size: 24px;
    line-height: 1.5;
    color: var(--shell-header-netapp-primary-text);
  }
  .move-saas-sub-title:hover {
    color: var( --shell-header-netapp-text-hover);
  }
  .benefit-title {
    font-size: 24px;
    color: var(--text-title);
    font-weight: 600;
    line-height: 1.08;
    text-align: left
  }
  .continue-to-saas-button {
    font-size: 13px;
    font-weight: 600;
  }
  .continue-to-saas-button:hover {
    background-color: var(--button-primary-bg-hover);
  }
  .learn-instructions {
    font-size: 14px;
    line-height: 1.71;
    margin-top: 25px;
    text-align: left;
  }
  .learn-more-btn {
    margin-left: 10px;
    height: 40px;
  }

  .learn-more-btn:hover {
    background-color: var(--background-content);
  }
  .close-icon {
    width:35px;
    height:35px;
    cursor: pointer;
    margin-top: 40px;
    margin-right: 40px;
    svg * {
      stroke: var(--text-reversed);
    }
  }
  .move-to-saas-icon {
    width: 391px;
    height: 350px;
    margin-left: 70px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .keep-using-button {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.85px;
    margin-top: 19px;
  }
}

.mask {
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: var(--background-main);
  top: 0;
  left: 0;
  z-index: $full-screen-z - 1;
  opacity: 0.1;
}

@media (max-width: 1366px) {
  .common-screen {
    .common-header {
      height: 120px;
    }
    .move-saas-title {
      font-size: 25px;
    }
    .move-saas-sub-title {
      font-size: 18px;
    }
    .move-saas-instructions {
      font-size: 14px;
    }
    .move-to-saas-icon {
      height:311px;
    }
    .learn-instructions {
      font-size: 13px;
    }
    .close-icon {
      width:20px;
      height: 20px;
    }
  }
}
