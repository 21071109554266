@use "@netapp/bxp-design-system-react/styles/cm-layout" as cmLayout;

:global {
  [class^="SlidingManagementWidget-module"] [class^="WizardLayout-module"] {
    height: calc(100vh - 75px);
  }

 [class^="TabLayout-module_tab-with-nav"] {
   height: calc(100vh - #{cmLayout.$main-header-height + cmLayout.$tab-header-height});
   overflow: hidden;
 }
}
