@use "../../app/components/MainHeader.module.scss";

.trigger {
  position: relative;

  svg {
    width: 20px;
    height: 24px;
  }
}

div.menu {
  width: 720px;
}

.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 6px;
  gap: 6px;

  position: absolute;
  top: 9px;
  left: 12px;
  font-size: 13px;
  font-weight: 590;
  line-height: 16px;
  border-radius: 24px;
  border: 2px solid var(--background-content);

  &.error, &.critical {
    background-color: var(--notification-error);
    color: var(--shell-header-notification-text-2);
  }

  &.warning {
    background-color: var(--chart-6);
    color: var(--shell-header-notification-text);
  }

  &.info, &.success, &.recommendation {
    background-color: var(--chart-5);
    color: var(--shell-header-notification-text);
  }
}

.content {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}

.widgets {
  flex: 0 0 20px;
  display: grid;
  grid-template-columns: 200px 1fr max-content;
  margin-bottom: 19px;

  > button {
    width: fit-content;
  }
}

.notifications {
  flex-grow: 1;
  flex-shrink: 0;
}

.notification-content{
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .cta {
    height: 24px;
    margin-bottom: 11px;
    white-space: nowrap;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .label {
    font-size: 14px;
    line-height: 1.71;
    color: var(--text-primary);
    font-weight: 590;
    word-break: break-word;
    margin-bottom: 15px;

    .critical{
      padding: 11px 0 4px 11px;
      background-color: var(--error-background);
      width: 100%;
      color: var(--notification-error);

      span{
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 550px;
      }
    }
  }

  .notification-info {
    display: flex;

    div:first-child {
      width: 450px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &:hover {
    box-shadow: 4px 4px 15px 0 var(--border-drop-shadow);

    .dismiss-one {visibility: visible;}
  }

  .dismiss-one {
    visibility: hidden;
    font-size: 13px;
    font-weight: normal;
    color: var(--text-secondary);
    position: absolute;
    margin-left: 496px;
    margin-top: 4px;

  }

  .dismiss-one-icon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
    --icon-primary: var(--text-secondary);
  }

  .value {
    font-size: 13px;
    color: var(--text-secondary);

    &:not(:last-child) {
      &:after {
        margin-left: 8px;
        content: "";
        display: inline-block;
        height: 12px;
        width: 1px;
        background-color: var(--text-secondary);
        position: relative;
        top: 2px;
      }
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .descriptions {
    padding-bottom: 11px;

  }
  .p-desc{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 520px;
  }

  .chevron:hover > span {
    background-color: var(--text-button-primary-hover);
  }
}

.notification-container-critical {
  display: grid;
  grid-template-columns: 45px 1fr;
  padding-bottom:16px;
  margin: initial;
  @extend .notification-content;
}

.notification-container-info,
.notification-container-error,
.notification-container-recommendation,
.notification-container-warning,
.notification-container-success  {
  display: grid;
  padding: 22px 26px 16px 22px;
  grid-template-columns: 20px 1fr 20px;
  grid-column-gap: 8px;
  margin: initial;
  @extend .notification-content;
}

.link-container{
   margin: 5px 0 5px 0;

  button{
    font-weight: normal;
  }
}

.icon-container-critical{
  background-color: var(--notification-error);
  height: 45px;
}

.priority-icon-color{
  &.info {
    --icon-primary: var(--notification-information);
  }

  &.error {
    --icon-primary: var(--notification-error);
  }

  &.critical {
    --icon-primary: var(--icon-reversed);
  }

  &.recommendation {
    --icon-primary: var(--chart-3);
    > path {
      fill: var(--chart-3);
    }
  }

  &.warning {
    --icon-primary: var(--notification-warning);
  }

  &.success {
    --icon-primary: var(--notification-success);
  }
}

.priority-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: -1px;

  @extend .priority-icon-color;
}


.priority-icon-critical {
  width: 30px;
  height:30px;
  margin-top: 7px;
  margin-left: 7px;

  @extend .priority-icon-color;
}

.priority-icon-recommendation {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  margin-left: -4px;

  @extend .priority-icon-color;
}



.dismissing-all {
  padding-top: 214px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--mask-bg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.no-notifications {
  margin-top: 214px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-height: 640px) {
    margin-top: 0;
    justify-content: center;
    height: 100%;
  }
}

.caret {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: var(--text-primary) transparent transparent transparent;
  position: relative;
  top: 10px;
  margin-left: 8px;

  &.active {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent var(--text-primary) transparent;
  }
}

.filter-popover {
  transform: translateY(16px);
}

.sliding-menu-footer-notification {
  @extend .sliding-menu-footer;
  height: 48px;
  padding: 14px 40px;
}

.limited-header {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
}



