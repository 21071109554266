@use "@netapp/bxp-design-system-react/styles/mixins";
@use "@netapp/bxp-design-system-react/styles/cm-layout" as layout;
@use "@netapp/bxp-design-system-react/styles/zindex";

.container {
  height: layout.$main-header-height;
  position: sticky;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: zindex.$main-header-z;

  background-color: var(--shell-header-netapp-bg);
  box-shadow: inset 0px -1px 0px var(--shell-header-separator-1);
  padding: 0 40px 0 26px;
  display: flex;

  grid-column-start: 1;
  grid-column-end: 3;
}

.container *:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--border-focus-outline-header);
  border-radius: 4px;
}

.title {
  position: relative;
  align-items: center;
  display: flex;
}

/* START Skip to main content styling */
.skip-content {
  border: 0;
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  min-height: fit-content;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  color: var(--shell-header-netapp-primary-text);
  background-color: var(--shell-header-netapp-bg);
  font-size: 12px;
  line-height: 11px;
  font-weight: 500;
  padding: 11px 12px 8px;
  display: inline-block;
  top: 22px;
	left: 234px;
}

.skip-content:focus {
	clip-path: none;
	overflow: visible;
	width: auto;
	height: auto;
	margin-top: 3px;
	padding: 8px 8px 6px;
}
/* END Skip to main content styling */

.main-link {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  color: var(--shell-header-netapp-primary-text);
  display: inline-flex;
  align-items: center;

  svg {
    width: 104px;
    height: 19px;
    --icon-primary: var(--shell-header-netapp-primary-text);
    margin-right: 24px;
  }
}

.sliding-header-menu {
  top: layout.$primary-header-height;
}

.sliding-menu-mask {
  top: layout.$primary-header-height;
}

.menu-widgets {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.widget-button {
  &:not(:last-of-type) {
    margin-right: 30px;
  }

  --icon-primary: var(--shell-header-netapp-icons);

  * {
    pointer-events: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    --icon-primary: var(--shell-header-netapp-text-hover)  ;
  }
}

.caption-button {
  composes: widget-button;
  --ux-chevron: var(--shell-header-netapp-primary-text);

  width: 132px;
  padding-right: 32px;
  border-right: solid 1px var(--shell-header-netapp-secondary-text)  ;
  text-align: left;
  position: relative;

  &:disabled {
    --ux-chevron: var(--text-disabled);
    .caption-button-title, .caption-button-value {
      color: var(--text-disabled);
    }
  }

  &:hover:not(:disabled) {
    --ux-chevron: var(--shell-header-netapp-text-hover)   !important;

    * {
      color: var(--shell-header-netapp-text-hover)   !important;
    }
  }
}

.caption-button-title {
  font-size: 13px;
  color: var(--shell-header-netapp-primary-text);
  font-weight: 590;
  line-height: 20px;
}

.caption-chevron {
  position: absolute !important;
  top: 8px;
  right: 32px;
}

.caption-button-value {
  @include mixins.text-ellipsis;
  font-size: 13px;
  color: var(--shell-header-netapp-secondary-text)  ;
  line-height: 20px;
}

.widget-button-active {
  --icon-primary: var(--shell-header-netapp-text-hover)  ;
}

.caption-button-active {
  >span * {
    background-color: var(--shell-header-netapp-text-hover)   !important;
  }

  * {
    color: var(--shell-header-netapp-text-hover)   !important;
  }
}

.menu-content {
  padding: 35px 40px;
  position: relative;
}

.menu-content-list-button {
  margin: 0 40px;
  width: calc(100% - 80px) !important;

  span {
    @include mixins.text-ellipsis;
  }

  svg {
    flex-shrink: 0;
  }
}

.menu-content-link-button {
  display: inline-flex;
  height: 59px;
  font-size: 14px;
  font-weight: normal;
  color: var(--text-primary);
  border-bottom: solid 1px var(--border-main);
  width: 100%;
  text-align: left;
  align-items: center;
  line-height: 1.3;
  @include mixins.text-ellipsis;

  &:hover {
    color: var(--text-button-primary-hover);
  }

  &:focus {
    outline-offset: 0px !important;
  }

  &:first-child {
    border-top: solid 1px var(--border-main);
  }

  &.no-top-border {
    border-top: none;
  }
}
.menu-content-link-button-disable{
  pointer-events: none;
  opacity:0.7;
}

.menu-content-link-button-active {
  color: var(--text-button-primary);
}

.sliding-menu-footer {
  width: 100%;
  height: 75px;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 2px 2px 6px 0 var(--border-drop-shadow);
  background-color: var(--shell-header-service-bg);
  padding: 20px 37px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 34px;
}

.sliding-menu-content{
  position: relative;
}

.menu-loader{
  position: absolute;
  left: 50%;
  top: calc(50% - 47px);
  transform: translate(-50%, -50%);
}

.notification {
  margin: 20px 40px;
}

.connector-slide-done {
  display: none;
}

.projects-container {
  height: 300px;
  overflow: auto;
  @include mixins.standard-scroll();
}

.edit-name-form {
  height: 117px;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 115px;
    border-bottom: 1px solid var(--border-main);

    .input-restrict {
      width: 65%;
    }
  }

  .button-space {
    margin-left: 16px;
  }
  
  .button-wrapper {
    display: flex;
    margin-left: 10px;
  }
}
.static-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  font-size: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 380px;
}
.edit-btn {
  --icon-primary: var(--icon-secondary);
  &:hover:not(:disabled) {
    --icon-primary: var(--icon-primary-hover);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.org-search-widget {
  margin: 10px 40px;
  margin-bottom: 25px;
}

.org-menu-content {

  .menu-content-link-button {

    &:first-child {
      border-top: 0;
    }

  }

}

.tenancy-icon-color {
  path {
    fill: var(--icon-tertiary) !important;
  }
}

.dropdown-icon > div:first-child > div:first-child > div:first-child svg {
  width: 28px !important;
  height: 28px !important;
}