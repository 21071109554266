@use "@netapp/bxp-design-system-react/styles/zindex";
@use "@netapp/bxp-design-system-react/styles/mixins";

.base {
  position: fixed;
  z-index: zindex.$full-widget-z;
  width: 1366px;
  right: 0;
  height: 100%;
  top: 0;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: var(--background-main);

  @include mixins.small {
    width: 1280px;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
}

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: zindex.$full-widget-mask-z;
  background-color: rgba(0, 0, 0, 0.4);
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.6s cubic-bezier(.25, .8, .25, 1);
}

.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(.25, .8, .25, 1);
}
