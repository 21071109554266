@use "@netapp/bxp-design-system-react/styles/zindex";

strong {
  font-weight: 590;
}

:global #portal-widget-frame-container {
  iframe {
    z-index: zindex.$portal-widget-z !important;
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-primary);
}

// To be removed once migrated to the latest version of design system library
body {
  [class*="select__menu-portal"] [class*="select__menu"] {
      input[type='checkbox'] {
        z-index: 1 !important;
        width: 18px !important;
        height: 18px !important;
      }
  }
}

.management-widget-open {
  overflow: hidden;

  [class*="Notification-module_global"] {
    z-index: zindex.$notifications-z + zindex.$full-widget-z;
    left: initial;
    right: 684px;
    transform: translateX(50%);
  }
}
