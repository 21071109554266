@import "./../layout.scss";

.base {
  width: 928px !important;
  height: 475px;
}

.create-first-account {
  padding: 50px 55px;
  position: relative;
  display: grid;
  grid-template-columns: 380px 1fr;


  .left-side {
    border-right: 1px solid var(--border-main);
    padding-top: 10px;

    svg {
      margin: 40px 0 0 40px;
    }
  }

  .right-side {
    margin: 10px 0 0 60px;
    position: relative;

    label {
      margin-top: 40px;
    }

    button {
      display: block;
      margin: 53px auto 0 auto;
      width: 180px;
      height: 40px;
    }

    .failed {
      width: 100%;
      display: flex;
      justify-content: center;
      color: var(--notification-error);
      font-size: 13px;
      margin-top: 8px;

      svg {
        width: 19px;
        height: 19px;
        margin-right: 5px;
      }

      [class*="primary-fill"] {
        fill: var(--notification-error);
      }


      [class*="primary-stroke"] {
        stroke: var(--notification-error);
      }
    }
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
