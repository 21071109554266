.aws-icon {
  width: 34px;
  height: 21px;
}

.azure-icon {
  width: 23px;
  height: 23px;
}

.on-prem-icon {
  width: 26px;
  height: 19px;
}

.gcp-icon {
  width: 30px;
  height: 24px;
}
