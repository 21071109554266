@use "@netapp/bxp-design-system-react/styles/mixins";

@mixin scope-container {
  overflow-x: hidden;
  position: relative;
  flex-grow: 1;
  scrollbar-color: var(--border-scroller) var(--background-main);
  scrollbar-width: thin;
  background-color: var(--background-main);
  margin: 0 auto;
}

@mixin org-summary-label-font-size($max-width, $font-size) {
  @media screen and (max-width: $max-width) {
    font-size: $font-size !important;
  }
}

.title-position {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 590;
  line-height: 32px;
}

.type-icon {
  svg {
    width: 30px;
    height: 30px;
  }
}

.grid-icon {
  --icon-primary: var(--text-primary);
}

.base-layout {
  height: 87vh;
}

.bread-com {
  grid-template-columns: auto;
}

.table-header {
  align-items: center;
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 590;
  line-height: 28px;
  margin: 5px 0px;
  width: 70%
}

.table-count {
  display: grid;
  grid-template-columns: 1fr 400px;
  margin-bottom: 20px;
  font-weight: 590;
  font-size: 16px;
  line-height: 28px;
  margin-top: 40px;
}

.table-search {
  width: 420px;
}

.table-margin {
  margin-top: 40px;
}

// Remove the default SVG icon when there are no resources, as we need to add the resource icon instead.
.no-resources-svg-hide {
  >div:nth-child(2) {
    >div:nth-child(2) {
      >svg:first-child {
        display: none;
      }
    }
  }
}

.table-pager-style {
  bottom: 1px;
  right: 7.7%;
  @media screen and (min-width: 2600px) {
    right: 25vw;
  }
}

.summary-info-type, .summary-info-large {
  margin-right: 10px !important;
  > div {
    &:nth-child(2) {
      overflow: hidden;
      justify-content: flex-start;
    }
  }
}

.summary-info-type > div:nth-child(2) span {
  @include org-summary-label-font-size(1430px, 13px);
  @include org-summary-label-font-size(1390px, 12px);
}

.accountid-label-responsive {
  span {
    @include org-summary-label-font-size(1430px, 13px);
    @include org-summary-label-font-size(1390px, 12px);
  }
}

.summary-info-large div div:first-child span {
    font-size: 28px;
    font-weight: 400;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
  .summary-header-container {
    padding: 32px 20px;
  }
}


.error-cell {
  border: 1px solid var(--notification-error);
  padding: 5px 2px;
}

.nosplitBtn {
  border: none !important;
  padding: 0px;
  background: transparent !important;
 > span::before {
  content: none !important;
 }
padding-left: 0px !important;
}

.summary-count-label {
  padding-left: 0px !important;
  margin-right: 5px !important;
  border-left: 0px !important;  
}

.summary-no-border {
  border: none !important;

  .summary-header-container {
    padding: 32px 20px;
  }
  
}

.summary-header-container {
  padding: 32px 20px !important; 
  >div:first-child>div {
    @media screen and (max-width: 1500px) {
      grid-column-gap: 12px !important;
    }
  }
}

.summary-header {
  padding-left: 5px !important;
  margin-right: 1px !important;
  height: auto;
}

.heading-tenancy,
.grid-header {
  margin-top: 20px !important;
}

.flex-box {
  display: flex;
}

.add-scope {
  width: min-content;
}

.scopes-header {
  display: flex;
  padding: 20px 0;

  .scopes {
    align-self: flex-end;
  }

  .add-scope {
    margin-left: 10px;
    width: 152px;
  }
}

.add-user {
  margin-left: auto;
}

.select-scope-menu {
  background-color: var(--background-content);
  border: 1px solid var(--border-main)
}

.table-outer-layout {
  height: calc(100vh - 430px);
  background-color: var(--background-content);
}

.add-scope-container {
  margin: 0 auto;
  width: min-content !important;
  @include scope-container;
}

.edit-scope-container{
  width: 78% !important;
  @include scope-container;
}

.breadcrumbs {
  padding-top: 24px;
  padding-left: 40px;
}

.resources {
  display: grid;
  grid-template-columns: min-content min-content;
  grid-column-gap: 24px;
}

.resource {
  width: 500px;
  z-index: 1;
  margin: auto;

  &.has-selection  {
    margin-bottom: 30px;
  }
}

.resource-card {
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-column-gap: 9px;
  flex: 1 1;
  padding: 32px;
}
.action-scope-form-container {
  width: 100%;
  height: calc(100% - 165px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  > section:first-child {
    padding-top: 10px;
  }
}

.footer {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--border-main);
  min-height: 60px;
}
.action-scope-input {
  width: 50% !important;
  margin-bottom: 28px !important;
}
.tenancy-error {
  width: 820px;
  position: fixed;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.dialog-error {
  width: 720px;
  position: fixed;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.resource-grid-scope-icon {
  width: 20px;
  height: 20px;
  path {
    fill: var(--icon-tertiary) !important;
  }
}

.delete-row-action-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.provider-bg-color {
  >div:first-child {
    background-color: #fff;
  }
}

.member-bg-color {
  >div:first-child {
    --icon-primary: var(--icon-reversed);
    background-color: #0054D7;
  }
}

.add-scope {
  margin-left: auto;
  width: 152px
}

.resource {
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1), border 0s;
  width: 500px;
  grid-template-rows: 1fr min-content;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: relative;


  &.has-selection :global {
    .circle-icon-shadow {
      display: none;
    }
  }
}


.type-count {
  font-weight: 590;
  font-size: 16px;
  line-height: 28px;
}

.select-placeholder {
  position: absolute;
    top: 10px;
    color: var(--text-disabled);
    font-size: 14px;
    padding: 0 16px;
}

.advance-search-filter {
  .select-wrapper {
    min-width: 270px;
    position: relative;
    label > div > div > div[class^='Select-module_multi-value-'], .select__single-value  {
      display: none;
    }
    
  }
  
}

.selected-tag {
    border-radius: 16px;
    border: solid 1px var(--button-secondary);
    background-color: var(--background-content);
    color: var(--button-secondary);
    font-size: 12px;
    height: 32px;
    display: inline-block;
    line-height: 30px;
    margin-right: 10px;
    --icon-primary: var(--button-secondary);
    
    span {
      padding: 0 20px 0px 10px;
      vertical-align: top;
    }
    button {
      margin-top: 3px;
    }

}

.action {
  border-top: 1px solid var(--border-main);
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;
  gap: 15px;
  margin-top: 20px;
}

.search {
  margin: 0px auto 40px auto !important;
}

.selected-tag {
  margin-top: 10px;
}

.info-icon {
  height: 25px;
  margin-right: 8px;
}

.table-cell {
  background-color: var(--selector-item-selection-bg);
  border-bottom: 2px solid var(--white);
  overflow-y: unset !important;
}

.table-column {
  background-color: var(--white);
  border-bottom: none;
}

.card-grid {
  box-shadow: none;
}

.allocate-action {
  background-color: var(--selector-item-selection-bg);
  padding: 20px 35px;
  margin-top: 10px;
  > div {
    display: flex;
    gap: 15px;
    width: 50%;
  }
}

.allocate-action-visible { 
  visibility: visible;
  opacity: 1;
  max-height: 60px;
  transition: visibility 300ms linear 0s, opacity 300ms;

}

.allocate-action-hidden { 
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  padding: 0;
}

.allocate-grid-header {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  > div { 
    display: flex;
    gap: 15px;
  }
  .filter {
    display: flex;
    gap: 12px;
    align-items: baseline;
  }
}

.no-resources {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.table-search-widget {
  margin-left: auto;
  width: 100%;
}

.select-scope-dropdown {
  >div:nth-child(2) {
    max-height: 100px !important;
    width: 400px;
  }
}

.orgs-col-alignment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    align-items: baseline;
    gap: 2px
}

.notification-message {
  width: 900px;
  position: absolute;
  bottom: 67px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999
}

.summary-icon {
  svg {
    fill: var(--icon-primary);
  }
}

.filter-icon {
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    width: 38px;
    height: 33.3px;
    margin-right: 12px;
    gap: 8px;
    padding: 10px 8px 10px 8px;    
    background-color: var(--background-table-header);
    @include mixins.color-svg($fill: var(--icon-primary));
  }
}

.grid-collapse-icon {
  path {
    fill: var(--icon-secondary) !important;
  }
}

.dark-icon {
  path {
    fill: var(--icon-tertiary) !important;
  }
}

.primary-icon {
  path {
    fill: var(--icon-primary) !important;
  }
}

.orgs-grid-link {
  color: var(--text-button-primary);
}

.copied {
  position: absolute;
  top: -30px;
  left: 10px;
  width: max-content;
  max-width: 320px;
  padding: 8px 12px;
  line-height: 20px;
  color: var(--text-primary);
  font-size: 13px;
  white-space: initial;
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0 var(--border-drop-shadow);
  background-color: var(--tooltip-info-bg);
}

.scope-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scope-heading > div {
  height: 14px;
}

.table-cell-users {
     overflow-y: unset;
}

.user-grid {
  div[class^='Table-module_rows-group-container'] > div {
    overflow-x: auto; 
    overflow-y: visible;
  }

  .table-pager-style {
    margin-top: 20px;
  }
  div[class*='Table-module_disabled-row'] div[data-component="Checkbox"] {
    position: relative;
    pointer-events: none;
    &:before {
      content: "";
      position: absolute;
      left: 0%;
      top: 0%;
      width: 24px;
      height: 24px;
      background-color: var(--white);
    }
    svg rect {
      stroke: var(--text-disabled) !important;
    }
  } 

  div[class*='Table-module_disabled-cell'] {
    color: var(--text-primary) !important;
  }
}

.user-select-dropdown {
  margin: 0 !important;
  label > div {
      border: none;
      padding: 0;
      background: inherit;
      div[class^='Select-module_placeholder'] {
        color: var(--button-secondary) !important;
        padding-left: 0 !important;
      }
      > div {
        top: 0px;
      }

      &:nth-child(2) {
        width: 19rem  !important;
        top: 1px !important;
        position: absolute  !important;
        background: white  !important;
      }
  }
  
}
 

.table-pager-user-grid {
  margin-top: 25px;
}

.define-role { 
  padding: 15px;
  margin-bottom: 10px;
  display: flex
}

.copy-to-clip {
  svg path{  
    fill: var(--icon-secondary);
  }
}

.user-grid-dropdown-menu {
  button {
    height: fit-content;
    padding: 3px 15px;
  }
   div {
    width: 380px !important;
  }
}

.popover-shift{
  margin-left: 15%;
}

.wrapping-text {
  line-height: 11px;
  word-break: break-word !important;
  font-weight: bold;
  font-size: 13px;
}

.text-ellipsis-accordion {
  width: 18rem;
  line-height: 11px;
}

.colum-cell-treegrid {
  display: grid;
  grid-template-columns: 20px 1fr;
}
.text-ellipsis-table-cell {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  line-height: 23px;
}

.scope-header {
  display: flex;
  justify-content: space-between;
}
.summary-info-item{
  padding: 32px 40px;
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-column-gap: 16px;
  flex: 1 1;
  white-space: nowrap;
}
.summary-info-resource {
  padding: 20px 0px 16px 30px;
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-column-gap: 16px;
  flex: 1 1;
  white-space: nowrap;
}
.user-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.resource-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc((100% - (4 * 20px)) / 5), 1fr));
    gap: 20px;
}
.card-content {
  display: flex;
  padding: 20px 0 20px 40px;
  gap: 32px;

  .icon-text-container {
    display: flex;
    gap: 16px;
    align-items: center;

    .resource-desc-icon {
      width: 24px;
      height: 24px;

      path {
        fill: var(--text-primary) !important;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.entity-type-color {
  color: var(--text-primary) !important;
}
.adv-tree-select {
  width: 270px !important;
}
.add-scope-button {
    height: 32px;
    font-size: 14px;
    padding: 0 24px;
    font-weight: 590;
}

.allocate-resources-svg-hide{
  >div:nth-child(1) {
    >div:nth-child(2) {
      >svg:first-child {
        display: none;
      }
    }
  }
}

.associate-header-cell {
  span {
    text-wrap: wrap;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Allow height to adjust dynamically and Set minimum height to 57px */
.large-scope-cell { 
    min-height: 57px !important;
    height: inherit !important;
  div {
    margin: 2px 0px !important;
    white-space: normal !important;
    word-break: break-word !important;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.scope-separator {
  color: var(--text-button-secondary) !important;
  margin: 0 5px;  
}

.text-wrap {
  white-space: normal;
  word-break: break-word;
}

.org-summary-container {
  display: grid;
  grid-template-columns: 0.49fr 0.51fr;
  gap: 24px; 
}

.base {
  width: 1000px !important;
}

.w-full{
  width: 100%;
}
.p-0 {
  padding: 0;
}

.role-select-dropdown {
  margin: 0 !important;
  label > div {
    padding: 0;
    background: inherit;

  }
}

.role-inner-grid-wrapper {
  width: 100%;
  .role-inner-grid-card {
    margin: 20px;
    padding: 20px;
  }
}

.text-right {
  text-align: right;
}

.width-full {
  width: 100%;
}

.change-btn{
  padding-left: 10px;
}
.role-change-text{
  width: 50%;
}
.modal-content{
  overflow-y: visible !important;
}
.modal-user-info{
  padding-bottom: 32px;
}

.federation-card {
  display: flex;
  box-shadow: 2px 2px 6px 0 var(--border-drop-shadow);
  background-color: var(--background-content);
}

.metric-item {
  padding: 20px 0px 20px 0px;
  flex: 1 1 auto !important;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-l-10 {
  padding-left: 10px;
}
.grid-col-3 {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  gap: 1.5em;
}

.mb-15 {
  margin-bottom: 15px;
}

.assign-heading {
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 1.5em;
}

.state-icon {
  display: inline-flex;
  gap: 16px;

  svg {
    width: 22px;
    height: 22px;
    position: relative;

    &.success {
      --icon-primary: var(--notification-success);
    }

    &.un-verified {
      --icon-primary: var(--notification-information);
    }
  }
}


.label-font {
  font-size: 14px !important;
}

div[class*="inline-value-metric"] {
  div[class*="summaryValue"] {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.domain-list {
  :last-child {
    border-bottom: none;
  }
}

.domains {
  border-bottom: 1px solid var(--border-main);
  padding: 12px;
  background-color: var(--background-main);
}

.pt-12 {
  padding-top: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-24 {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-main);
}

.r-border {
  border-right: 1px solid var(--border-main);
}

.sub-row-style {
  background-color: var(--background-content);
  width: 100%;
  padding: 30px;
}

.multiroles-summary{
  > div {
    overflow-x: auto ;
  }
}

.multi-role-title{
  > div {
    overflow: visible !important;
  }
}

