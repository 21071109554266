@use "./modules/app/layout.scss";
@use "@netapp/bxp-design-system-react/styles/mixins";
@use "@netapp/bxp-design-system-react/styles/zindex";

.base {
  display: grid;
  grid-template-rows: layout.$main-header-height 1fr;

  grid-template-columns: 72px 1fr;
  height: 100vh;

  @include mixins.small {
    grid-template-columns: 48px 1fr;
  }
}

.initializing {
  grid-template-columns: 1fr;
}

.container {
  position: relative;
  overflow: hidden;
  background-color: var(--background-main);
}

.doodads {
  z-index: zindex.$main-header-z + 1;
  position: fixed;
}