@use "@netapp/bxp-design-system-react/styles/mixins";

.search-widget-container {
  margin: 34px 40px 5px 40px;
}

.search-widget {
  border-bottom: solid 1px var(--border-main) !important;
}

.no-connector {
  display: flex;
  align-items: center;
  padding-top: 103px;
  flex-direction: column;

  .icon-position {
    margin-bottom: 32px;
    opacity: 0.7;
  }

  svg {
    width: 54px;
    height: 54px;
    padding: 4px;
    @include mixins.color-svg($primary: var(--icon-primary-disabled));

  }

  a, button {
    margin-top: 23px;
    width: 279px;
  }
}

.item {
  height: 116px;
  display: flex;
  flex-direction: column;
  padding: 22px 40px 0;

  @include mixins.text-ellipsis;

  &:after {
    position: absolute;
    bottom: 0;
    right: 33px;
    left: 40px;
    content: "";
    border-bottom: 1px solid var(--border-main);
  }

  &:hover {
    background-color: var(--background-hover);
    box-shadow: 1px 1px 2px 1px var(--border-drop-shadow);
    z-index: 1;

    &:after {
      display: none;
    }
  }
}

.checkbox {
  span {
    @include mixins.text-ellipsis;
    margin-right: 5px;
  }

  margin-right: 24px;

  svg {
    flex-shrink: 0;
  }
}

.item-detail {
  font-size: 13px;
  color: var(--text-secondary);
  margin-right: 10px;
  line-height: 11px;
  display: inline-block;

  &:not(:first-child) {
    border-left: 1px solid var(--text-secondary);
    padding-left: 10px;
  }
}

.checkbox-disabled {
  color: var(--text-disabled) !important;
}

.item-status {
  text-transform: capitalize;
}

.details-row {
  margin-left: 33px;
  margin-top: 13px;
}

.need-upgrade-notice {
  margin-top: 12px;
  margin-left: 33px;
  font-size: 13px;
  line-height: 1.38;
  color: var(--text-primary);

  button {
    font-size: 13px;
  }
}

.list {
  @include mixins.standard-scroll;
}

.box {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 2px 7px 1px 0;
}

.box-active {
  composes: box;
  background-color: var(--notification-success);
}

.box-inactive, .box-failed {
  composes: box;
  background-color: var(--notification-error);
}

.box-pending {
  composes: box;
  background-color: var(--notification-warning);
}

.item:hover .delete {
  display: block;
}

.menu {
  top: 31px;
  font-size: 13px !important;
  white-space: nowrap;
}

.top-line-info {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.disable-pointers {
  pointer-events: none;
}