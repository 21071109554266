@use "@netapp/bxp-design-system-react/styles/mixins";
@use "@netapp/bxp-design-system-react/styles/zindex";

@mixin width {
  width: 72px;

  @include mixins.small {
    width: 48px;
  }
}

$left-nav-container-width: 264px;

.wrapper {
  position: relative;
  z-index: zindex.$main-header-z - 1;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  @include width;

  background-color: var(--shell-main-nav-bg);
  box-shadow: 2px 2px 6px var(--border-drop-shadow);
  z-index: 2;
  overflow: hidden;
  @include mixins.standard-scroll(11px);

  .label {
    opacity: 0;
    transform: translateX(-25px);
  }
}

.container *:focus-visible, .sub-tab:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--border-focus-outline);
  border-radius: 4px;
}

.entering {
  transition: width 400ms cubic-bezier(.25, .8, .25, 1);
  width: $left-nav-container-width;
  overflow-y: auto;

  &.has-scroll {
    width: 275px;
  }

  .label {
    transition: opacity 400ms cubic-bezier(.25, .8, .25, 1), transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-delay: 50ms, 0s;
    opacity: 1;
    transform: translateX(-12px);

    @include mixins.small {
      transform: translateX(0);
    }
  }

  .chevron {
    transition: opacity 400ms cubic-bezier(.25, .8, .25, 1);
    transition-delay: 50ms, 0s;
    opacity: 1;
  }
}

.entered {
  width: $left-nav-container-width;
  overflow-y: auto;

  &.has-scroll {
    width: 275px;
  }

  .label {
    opacity: 1;
    transform: translateX(-12px);

    @include mixins.small {
      transform: translateX(0);
    }
  }

  .chevron {
    opacity: 1;
  }

  .sub-tabs-container {
    opacity: 1;
    pointer-events: auto;
  }
}

.exiting {
  transition: width 400ms cubic-bezier(.25, .8, .25, 1);
  @include width;

  .label {
    transition: opacity 300ms cubic-bezier(.25, .8, .25, 1), transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
    transform: translateX(-25px);
  }

  .chevron {
    transition: opacity 300ms cubic-bezier(.25, .8, .25, 1);
    opacity: 0;
  }
}

.category {
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: $left-nav-container-width;
  height: 56px;

  font-size: 14px;
  color: var(--shell-main-nav-icon-text);
  --icon-primary: var(--shell-main-nav-icon-text);
  font-weight: 600;

  &:hover:not(.category-active) {
    background-color: var(--shell-main-nav-bg-selected);
  }

  .chevron {
    margin: 0 16px 0 auto;
    transform: rotate(-90deg);
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    --icon-primary: var(--shell-main-nav-icon-text);
  }
}

.category-active {
  color: var(--shell-main-nav-icon-text-selected);
  --icon-primary: var(--shell-main-nav-icon-text-selected);
}

.category-active:not(.category-open) {
  background-color: var(--shell-main-nav-bg-selected);
}

.category-open {
  .chevron {
    transform: rotate(0);
  }
}

.icon {
  @include width;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    pointer-events: none;
  }
}

.service {
  width: $left-nav-container-width;
  height: 56px;
  position: relative;
  --icon-primary: var(--shell-main-nav-icon-text);

  > a {
    width: 99%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    line-height: 17px;
    color: var(--shell-main-nav-icon-text);
    margin-left: 2px;
    padding-left: 60px;
    padding-right: 40px;

    @include mixins.small {
      padding-left: 48px;
    }

    font-size: 14px;
    font-weight: 590;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 21px;
  }

  &:hover:not(.service-active) {
    background-color: var(--shell-main-nav-bg-hover);
  }

  .label {
    padding-left: 12px;

    @include mixins.small {
      padding-left: 0;
    }
  }

  .service-active {
    background-color: var(--shell-main-nav-bg-selected);
    color: var(--shell-main-nav-icon-text-selected);
  }
}

.also-known-as {
  transform: translate(32px, calc(-50% + 7px)) !important;
  pointer-events: none;
}

.sub-tabs-container {
  position: fixed;
  width: 223px;
  box-shadow: 2px 2px 6px var(--border-drop-shadow);
}

.sub-tab {
  background-color: var(--shell-main-nav-bg);
  height: 48px;
  display: flex;
  padding: 0 24px;
  align-items: center;
  font-weight: 510;
  font-size: 13px;
  line-height: 16px;
  color: var(--shell-main-nav-icon-text);

  &:hover {
    background-color: var(--shell-main-nav-bg-hover);
  }
}

.sub-tab-active {
  color: var(--shell-main-nav-icon-text-selected);
}

// Reducing the width on focus so that outline is visible properly.
.category:focus-visible, .service > a:focus-visible {   
  width: $left-nav-container-width - 10px;
  margin: 5px;
}