@use "@netapp/bxp-design-system-react/styles/cm-layout" as cmLayout;
@use "@netapp/bxp-design-system-react/styles/zindex";

.base {
  width: 715px;
  height: 65px;
  position: fixed;
  top: cmLayout.$main-header-height + cmLayout.$tab-header-height + 34px;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 238px 1fr;
  z-index: zindex.$floating-banner-z;
}

.purple {
  background-color: var(--chart-9);
  padding-left: 53px;
  display: flex;
  align-items: center;
  p {
    color: var(--text-reversed);
    width: 147px;
    text-align: center;
    line-height: 1.47;
  }

  svg {
    position: absolute;
    left: 4px;
    top: -71px;
    height: 135px;
  }
}

.text {
  display: flex;
  padding-left: 40px;
  align-items: center;

  [class*="primary-fill"] {
    fill: var(--icon-secondary);
  }
}

.close {
  margin: 0 0 0 24px;
}
