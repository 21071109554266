@import "@netapp/bxp-design-system-react/styles/mixins";

.content {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.detail {
  height: 115px;
  position: relative;
  padding-top: 35px;

  &:first-child {
    padding-top: 40px;
  }

  border-bottom: 1px solid var(--border-main);
}

.details-value {
  @include text-ellipsis;
  font-size: 14px;
  color: var(--text-primary);
  margin-bottom: 10px;
}

.details-label {
  font-size: 13px;
  font-weight: 590;
  color: var(--text-secondary);
}