@use "./layout.scss";
@use "@netapp/bxp-design-system-react/styles/zindex";
@use "@netapp/bxp-design-system-react/styles/mixins";

.base {
  width: calc(100% - 72px);
  height: calc(100% - #{layout.$main-header-height});
  top: layout.$main-header-height;
  padding: 0;
  margin: 0;
  position: fixed;
  border: none;
  z-index: 2;

  left: 72px;

  @include mixins.small {
    width: calc(100% - 48px);
    left: 48px;
  }
}

.mask {
  width: calc(100% - 72px);
  height: calc(100% - #{layout.$main-header-height});
  top: layout.$main-header-height;
  padding: 0;
  margin: 0;
  position: fixed;
  z-index: 3;

  left: 72px;
  @include mixins.small {
    width: calc(100% - 48px);
    left: 48px;
  }
}

.sub-tab {
  height: calc(100% - #{layout.$main-header-height + layout.$tab-header-height});
  top: layout.$main-header-height + layout.$tab-header-height;
}

.full-pane {
  top: 0;
  height: 100%;
  z-index: zindex.$full-pane-angular-z;
}

.main-bar {
  position: fixed !important;
  width: 100%;
  top: layout.$main-header-height;
  z-index: 1;
}
